import axios from "axios";
import React, { useEffect, useState } from "react";
import CompanyDetailsModal from "../../components/CompanyDetailsModal";

const Companies = () => {
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/companies`
      );
      if (response?.data?.success) {
        setData(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-4">
      <table className="w-full text-sm text-right rtl:text-right text-gray-700">
        <thead className="text-xs text-gray-800 uppercase bg-white">
          <tr>
            <th scope="col" className="px-6 py-3">
              اسم الشركة
            </th>
            <th scope="col" className="px-6 py-3">
              رقم الجوال
            </th>
            <th scope="col" className="px-6 py-3">
              <span className="sr-only">عرض كامل المعلومات</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <tr key={index} className="bg-white border-b hover:bg-gray-50">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                {item?.companyName}
              </td>
              <td className="px-6 py-4">
                <a
                  href={`https://web.whatsapp.com/send?phone=+962${item.phoneNumber}`}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:underline"
                >
                  {item?.companyMobileNumber}
                </a>
              </td>
              <td className="px-6 py-4 text-right">
                <button
                  onClick={() => setOpenModal(true)}
                  className="font-medium text-blue-600 hover:underline"
                >
                  عرض كامل المعلومات
                </button>
              </td>

              {openModal && (
                <CompanyDetailsModal
                  data={item}
                  onClose={() => setOpenModal(false)}
                />
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Companies;
